@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #ffffff;
  --secondary: #f8fafc;
  --accent: #22c55e;
  --accent-hover: #16a34a;
  --text: #334155;
  --light: #f8fafc;
}

@layer base {
  body {
    font-family: 'Inter', system-ui, -apple-system, sans-serif;
    @apply text-text bg-light;
  }
}

@layer components {
  .section {
    @apply py-32 relative overflow-hidden;
  }

  .section-title {
    @apply text-4xl md:text-5xl font-extrabold text-center mb-16 text-primary relative;
  }

  .section-title::after {
    content: '';
    @apply block w-20 h-1 bg-accent mx-auto mt-4;
  }

  .section-title.text-white::after {
    @apply bg-white;
  }
}

@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-pulse-slow {
  animation: pulse 2s ease-in-out infinite;
}

.loader-wrapper {
  @apply fixed inset-0 bg-primary flex justify-center items-center z-50 transition-opacity duration-500;
}

.loader {
  @apply w-20 h-20 border-4 border-white/10 rounded-full border-t-accent animate-spin;
}

.testimonial-card {
  @apply bg-white p-10 rounded-2xl shadow hover:shadow-lg transform hover:-translate-y-2 transition-all duration-300 flex flex-col;
}

.contact-form input,
.contact-form textarea {
  @apply w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-text placeholder-text/70 focus:border-accent focus:bg-white/10 transition-all duration-300;
}

.badge {
  @apply flex items-center gap-4 px-10 py-6 bg-white rounded-full shadow hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300;
}

.client-item {
  @apply bg-white/5 backdrop-blur-lg p-12 rounded-xl border border-white/10 transform-gpu hover:-translate-y-2 hover:bg-white/10 transition-all duration-300;
}

.service-card {
  @apply bg-white p-12 rounded-xl shadow hover:shadow-lg transform-gpu hover:-translate-y-2 hover:rotate-1 transition-all duration-300 relative overflow-hidden;
}

.button {
  @apply px-8 py-3 bg-accent text-white font-semibold rounded-full hover:bg-accent-hover border-2 border-accent hover:text-white transition-all uppercase tracking-wide relative overflow-hidden;
}


@keyframes blob {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(20px, -50px) scale(1.1);
  }
  50% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  75% {
    transform: translate(40px, 40px) scale(1.05);
  }
}

.animate-blob {
  animation: blob 10s infinite ease-in-out;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}
